<template>
  <page-header-wrapper>
    <div class="dashboard-editor-container">
      <div class="content-wrapper">
        <a-tabs  v-model:activeKey ="activeName"  @change="handleClick">
          <a-tab-pane tab="签名管理" key="sign">
            <div class="table-operations">
                <a-button  type="primary"  @click="handleSignAdd" > <a-icon type="plus" /> 新增</a-button>
            </div>
            <div class="table-content-class">
              <a-table
                :loading="loading"
                :size="tableSize"
                rowKey="id"
                :columns="signColumns"
                :data-source="signList"
                :pagination="false"
                :bordered="tableBordered"
              >
               <span slot="applyStatus" slot-scope="text, record">
                  <!-- 1：未审核 2：审核通过 3：审核不通过-->
                  <a-tag color="blue" v-if="record.applyStatus === 1">待审核</a-tag>
                  <a-tag  color="#87d068" v-if="record.applyStatus === 2">审核通过
                    <a-tooltip effect="dark" :title="record.applyReply" placement="right">
                      !
                    </a-tooltip>
                  </a-tag>
                  <a-tag color="#f50" v-if="record.applyStatus===3">审核驳回
                    <a-tooltip effect="dark" :title="record.applyReply" placement="right">
                      !
                    </a-tooltip>
                  </a-tag>
                </span>
                <span slot="signId" slot-scope="text, record">
                   <a-tag  color="#87d068" v-if="record.sign === defaultSign">默认签名 </a-tag>
                   <a-tag  color="blue" v-else>未用签名 </a-tag>
                </span>
                <span slot="operation" slot-scope="text, record">
                   <a @click="confirmHandleSignUse(record)" v-if="record.sign !== defaultSign">
                    设为默认签名
                  </a>
                  <a-divider type="vertical" v-if="record.sign !== defaultSign"/>
                  <a @click="handleSignUpdate(record)">
                    <a-icon type="edit" />
                    修改
                  </a>
                  <a-divider type="vertical"/>
                  <a-popconfirm
                     ok-text="是"
                     cancel-text="否"
                     @confirm="handleSignDelete(record)"
                   >
                    <span slot="title">确认删除<b>{{ record.sign }}</b>吗?</span>
                    <a>  <a-icon type="delete" />{{$t('通用.按钮.删除')}}</a>
                  </a-popconfirm>
                </span>
              </a-table>
            </div>
          </a-tab-pane>
          <a-tab-pane tab="模板管理" key="template">
            <div class="table-operations">
              <a-button type="primary"  @click="handleTemplateAdd" > <a-icon type="plus" />{{$t('通用.按钮.新增')}}</a-button>
            </div>
            <div class="table-content-class">
              <a-table
                :loading="loading"
                :size="tableSize"
                rowKey="id"
                :columns="templateColumns"
                :data-source="templateList"
                :pagination="false"
                :bordered="tableBordered"
              >
                <span slot="categoryId" slot-scope="text, record">
                  <!-- 1：未审核 2：审核通过 3：审核不通过-->
                  <a-tag color="#87d068" v-if="record.categoryId===1">验证码</a-tag>
                  <a-tag type="#87d068" v-if="record.categoryId===2">通知&订单</a-tag>
                  <a-tag type="#87d068" v-if="record.categoryId===3">营销</a-tag>
                </span>
               <span slot="applyStatus" slot-scope="text, record">
                  <!-- 1：未审核 2：审核通过 3：审核不通过-->
                  <a-tag color="blue" v-if="record.applyStatus === 1">待审核</a-tag>
                  <a-tag  color="#87d068" v-if="record.applyStatus === 2">审核通过
                    <a-tooltip effect="dark" :title="record.applyReply" placement="right">
                      !
                    </a-tooltip>
                  </a-tag>
                  <a-tag color="#f50" v-if="record.applyStatus===3">审核驳回
                    <a-tooltip effect="dark" :title="record.applyReply" placement="right">
                      !
                    </a-tooltip>
                  </a-tag>
                </span>
                <span slot="operation" slot-scope="text, record">
                  <a @click="handleSendSms(record)" >测试</a>
                  <a-divider type="vertical"/>
                  <a @click="handleTemplateSyn(record)">
                    <a-icon type="redo" />
                    同步
                  </a>
                  <a-divider type="vertical"/>
                  <a @click="handleTemplateUpdate(record)">
                    <a-icon type="edit" />
                    修改
                  </a>
                  <a-divider type="vertical"/>
                  <a-popconfirm
                    ok-text="是"
                    cancel-text="否"
                    @confirm="handleTemplateDel(record)"
                  >
                    <span slot="title">确认删除<b>{{ record.id }}</b>吗?</span>

                    <a>  <a-icon type="delete" />{{$t('通用.按钮.删除')}}</a>
                  </a-popconfirm>
                </span>
              </a-table>
            </div>
          </a-tab-pane>

          <a-tab-pane tab="购买资源包" key="resources">
            <recharge :channelId="channelId"></recharge>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>



    <!--签名提添加与修改-->
    <a-drawer  :visible.sync="signOpen" width="700" @close="cancelSign">
      <a-divider orientation="left">
        <b>{{ signTitle }}</b>
      </a-divider>
      <a-form-model ref="signForm" :model="signForm" :rules="signRules" laba-width="80px">
        <a-form-model-item label="签名名称" prop="sign" >
          <div class="tips">
            <a-tooltip effect="light"  placement="top">
              <div slot="content">1、接口短信最多支持10个签名。如需更多签名，请联系客服处理。<br/>2、人工审核将在10分钟内完成，请耐心等待！(工作时间:8:30~21:00) 如对审核状态有疑义，请联系客服。</div>
              <i class="a-icon-info">编辑须知</i>
            </a-tooltip>
          </div>
          <a-input v-model="signForm.sign" placeholder="长度限2-16个字符，建议填写真实产品名/公司简称" />
          <div class="tips"> 示例：天猫 招商银行 顺丰快递 </div>
        </a-form-model-item>
        <a-form-model-item label="应用场景" prop="description">
          <a-input v-model="signForm.description" type="textarea" placeholder="请描述您的业务使用场景" />
        </a-form-model-item>
      </a-form-model>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitSignForm">确 定</a-button>
          <a-button @click="cancelSign">取 消</a-button>
        </a-space>
      </div>
    </a-drawer>




    <!--模板提添加与修改-->
    <a-drawer :visible.sync="templateOpen" width="700"   @close="cancelTemplate">
      <a-divider orientation="left">
        <b>{{ templateTitle }}</b>
      </a-divider>
      <a-form-model ref="templateForm" :model="templateForm" :rules="templateRules" laba-width="80px">
        <a-form-model-item label="模板名称" prop="templateName">
          <a-input v-model="templateForm.templateName" placeholder="请输入短信模板名称" />
        </a-form-model-item>
        <a-form-model-item label="模板编码" prop="code">
          <a-input v-model="templateForm.code" placeholder="请输入模板编码"/>
        </a-form-model-item>
        <a-form-model-item label="模板类型" prop="categoryId">
          <a-radio-group v-model="templateForm.categoryId" button-style="solid">
            <a-radio-button v-for="(d, index) in dict.type.extra_sms_template_type" :key="index" :value="parseInt(d.value)">{{ d.label }}</a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="模板内容" prop="template">
          <div class="tips">
            <a-tooltip effect="light"  placement="top">
              <div slot="content">
                1、不支持全变量模板；
                <br/>
                2、{序号}为需要接口替换的变量，变量序号从1开始，序号只允许数字；
                <br/>
                3、最多支持10个变量,变量格式如{1}、{2}、{3}；
                <br/>
                4、变量允许通过最长字符为20字符；
                <br/>
                5、短信模板首尾不能添加[]且任意位置不能添加【】符号；
                <br/>
                6、内容不用添加签名, 签名请单独申请，短信发送需要签名和短信内容；
                <br/>
                人工审核将在10分钟内完成，请耐心等待！(工作时间:8:30~21:00) 如对审核状态有疑义，请联系客服。</div>
              <i class="a-icon-info">编辑须知</i>
            </a-tooltip>
          </div>
          <a-input  type="textarea" v-model="templateForm.template" placeholder="请输入短信内容，链接网址必须前后空格，短信内容不能超出500个字" />
          <div class="tips"> 示例：你好，你的验证码是{1}，请在{2}分钟内使用。 </div>
        </a-form-model-item>
        <a-form-model-item label="模板描述" prop="description">
          <a-input v-model="templateForm.description" type="textarea" placeholder="请描述您的业务使用场景" />
        </a-form-model-item>
      </a-form-model>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitTemplateForm">确 定</a-button>
          <a-button @click="cancelTemplate">取 消</a-button>
        </a-space>
      </div>
    </a-drawer>

    <!-- 对话框(发送短信) -->
    <a-modal title="测试发送短信" :visible.sync="sendSmsOpen" width="500px" append-to-body @cancel="cancelSendSms">
      <a-form-model ref="sendSmsForm" :model="sendSmsForm" :rules="sendSmsRules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="模板内容" prop="templateCode">
          <a-input v-model="sendSmsForm.content" type="textarea" placeholder="请输入模板内容" disabled />
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="mobile">
          <a-input v-model="sendSmsForm.mobile" />
        </a-form-model-item>
        <a-form-model-item v-for="param in sendSmsForm.params" :key="param" :label="'参数 {' + param + '}'">
          <a-input v-model="sendSmsForm.templateParams[param]" :placeholder="'请输入 ' + param + ' 参数'" />
        </a-form-model-item>
      </a-form-model>
      <div slot="footer" class="dialog-footer">
        <a-button type="primary" @click="submitSendSmsForm">确 定</a-button>
        <a-button @click="cancelSendSms">取 消</a-button>
      </div>
    </a-modal>

  </page-header-wrapper>
</template>

<script>
import {tableMixin} from '@/store/table-mixin'
import ZRPanelGroup from './ZRPanelGroup'
import Recharge from './Recharge'
import {
  getAccount ,getSignList,createSign,updateSign,getTemplateList,delSign,
  createTemplate,updateTemplate,getTemplate,delTemplate,synTemplate
} from "@/api/sms/zrwinfo";
import {sendSms} from "@/api/sms/template";
import {getChannel, updateChannel} from "@/api/sms/channel";
export default {
  name: 'ZrInfo',
  mixins: [tableMixin],
  dicts: ['extra_sms_template_type'],
  components: {
    Recharge,
    ZRPanelGroup
  },
  data() {
    return {
      labelCol: {span: 5},
      wrapperCol: {span: 18},
      sendSmsOpen: false,
      sendSmsForm: {
        params: [], // 模板的参数列表
      },
      sendSmsRules: {
        mobile: [{required: true, message: "手机不能为空", trigger: "blur"}],
        templateCode: [{required: true, message: "模版编码不能为空", trigger: "blur"}],
        templateParams: {}
      },
      search: false,
      // 遮罩层
      loading: true,
      activeName:"sign",
      signList:[],
      signOpen:false,
      signTitle:"",
      defaultSign:"",
      signForm:{},
      signRules: {
        sign: [
          { required: true, message: "签名名称不能为空", trigger: "blur" }
        ],
        description: [
          { required: true, message: "应用场景不能为空", trigger: "blur" }
        ]
      },

      signColumns: [
        {
          title: '签名Id',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '签名',
          dataIndex: 'sign',
          align: 'center'
        },
        {
          title: '使用状态',
          dataIndex: 'signId',
          scopedSlots: {customRender: 'signId'},
          align: 'center'
        },
        {
          title: '扩展号',
          dataIndex: 'extNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '审核状态',
          dataIndex: 'applyStatus',
          align: 'center',
          scopedSlots: {customRender: 'applyStatus'},
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'},
          align: 'center'
        }
      ],


      templateList:[],
      templateOpen:false,
      templateTitle:"",
      templateForm:{
      },
      templateRules: {
        templateName: [
          { required: true, message: "模板名称不能为空", trigger: "blur" }
        ],
        code: [
          { required: true, message: "模板编号不能为空", trigger: "blur" }
        ],
        categoryId: [
          { required: true, message: "模板类型不能为空", trigger: "blur" }
        ],
        template: [
          { required: true, message: "模板内容不能为空", trigger: "blur" }
        ],
        description: [
          { required: true, message: "应用场景不能为空", trigger: "blur" }
        ]
      },
      templateColumns: [
        {
          title: '模板ID',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '模板名称',
          dataIndex: 'templateName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '模板编码',
          dataIndex: 'code',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '模板内容',
          dataIndex: 'template',
          align: 'center'
        },
        {
          title: '短信类型',
          dataIndex: 'categoryId',
          align: 'center',
          scopedSlots: {customRender: 'categoryId'},
        } ,
        {
          title: '审核状态',
          dataIndex: 'applyStatus',
          align: 'center',
          scopedSlots: {customRender: 'applyStatus'},
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'},
          width: '15%',
          align: 'center'
        }
      ],
      zrAccount:[],
      channelId : null
    }
  },
  created() {
    this.channelId = this.$route.params && this.$route.params.id;
    getAccount(this.channelId).then(response => {
      this.zrAccount = []
      let total = 0;
      Object.keys(response.data).forEach((key) => {
        this.zrAccount.push({'name':key,"value":response.data[key]})
        total = total + response.data[key]
      })
      this.zrAccount.unshift({'name':"短信总计","value":total})
    });
    this.getSignList(this.channelId)
  },
  methods: {
    handleClick(tab, event) {
      if(tab ==="sign"){
        this.getSignList(this.channelId)
      }else if(tab ==="template"){
        this.getTemplateList(this.channelId)
      }
    },
    getSignList(id){
      getSignList(id).then(response => {
        this.loading = false
        if(response.code ===0){
          this.defaultSign = response.sing;
          this.signList=response.data
        }else{
          this.$message.error(response.msg);
        }
      });
    },
    getTemplateList(id){
      getTemplateList(id).then(response => {
        this.loading = false
        if(response.code ===0){
          this.templateList=response.data
        }else{
          this.$message.error(response.msg);
        }

      });
    },
    handleSignAdd(){
      this.signOpen = true;
      this.signTitle = "添加签名";
      this.signReset();
    },
    handleSignUpdate(row){
      this.signForm =  {
        id:row.id,
        sign: row.sign,
        description: row.description,
      }
      this.signOpen = true;
      this.signTitle = "修改签名"
    },
    handleSignDelete(row){

        delSign(this.channelId,row.id).then(response => {
          this.getSignList(this.channelId);
          if(response.code ===0){
            this.$message.success("删除成功");
          }else{
            this.$message.error(response.msg);
          }
        }).catch(() => {})
    },
    submitSignForm(){
      this.$refs.signForm.validate(valid => {
        if (valid) {
          if (this.signForm.id != undefined) {
            updateSign(this.channelId,this.signForm.id,this.signForm.sign,this.signForm.description).then(response => {
              if(response.code ===0){
                this.signOpen = false;
                this.$message.success("修改成功");
              }else{
                this.$message.error(response.msg);
              }
              this.getSignList(this.channelId);
            });
          } else {
            let signData = {
              id:this.channelId,
              sign:this.signForm.sign,
              description:this.signForm.description
            }
            createSign(signData).then(response => {
              if(response.code ===0){
                this.signOpen = false;
                this.$message.success("添加成功");
              }else{
                this.$message.error(response.msg);
              }

              this.getSignList(this.channelId);
            });
          }
        }
      });
    },
    cancelSign(){
      this.signOpen = false;
      this.signReset();
    },
    signReset(){
      this.signForm = {
        id:undefined,
        sign: undefined,
        description: undefined
      };
      this.$nextTick(() => {
        this.$refs.signForm.resetFields()
      })

    },
    handleTemplateAdd(){
      this.templateReset();
      this.templateOpen = true;
      this.templateTitle = "添加模板";
    },
    handleTemplateUpdate(row){
      this.templateReset();
      this.templateOpen = true;
      getTemplate(this.channelId,row.id).then(response => {
        if(response.code ===0){
          this.templateForm = response.data
        }else{
          this.$message.error(response.msg);
        }
      });
      this.templateTitle = "修改模板"
    },
    handleTemplateDel(row){

      delTemplate(this.channelId,row.id).then(response => {
        this.getTemplateList(this.channelId);
        if(response.code ===0){
          this.$message.success("删除成功");
        }else{
          this.$message.error(response.msg);
        }
      }).catch(() => {})

    },
    cancelTemplate(){
      this.templateOpen = false;
      this.templateReset();
    },
    templateReset(){
      this.templateForm = {
        id:undefined,
        categoryId: 1,
        templateName: undefined,
        code:undefined,
        template: undefined,
        description: undefined
      };
      this.$nextTick(() => {
        console.log( this.$refs["templateForm"])
        this.$refs["templateForm"].resetFields()
      })
    },
    submitTemplateForm(){
      this.$refs["templateForm"].validate(valid => {
        if (valid) {
          this.templateForm.channelId = this.channelId
          if (this.templateForm.id != undefined) {
            this.templateForm.channelId = this.channelId
            updateTemplate(this.templateForm).then(response => {
              if(response.code ===0){
                this.templateOpen = false;
                this.$message.success("修改成功");
              }else{
                this.$message.error(response.msg);
              }
              this.getTemplateList(this.channelId);
            });
          } else {
            createTemplate(this.templateForm).then(response => {
              if(response.code ===0){
                this.templateOpen = false;
                this.$message.success("添加成功");
              }else{
                this.$message.error(response.msg);
              }

              this.getTemplateList(this.channelId);
            });
          }
        }
      });
    },
    confirmHandleSignUse(row){
      getChannel(this.channelId).then(response => {
        let  form = response.data;
        form.signature = "【"+row.sign+"】"
        let that  = this;
        updateChannel(form).then(response => {
          if(response.success){
            this.getSignList(this.channelId)
            this.$message.success("修改默认签名成功");
          }else {
            this.$message.error(response.message);
          }
        })
      })


    },
    /**同步掌榕短信模板到平台**/
    handleTemplateSyn(row){
      synTemplate(this.channelId,row.id).then(response => {
        if(response.code ===0){
          this.$message.success("同步成功");
          this.getTemplateList(this.channelId);
        }else{
          this.$message.error(response.msg);
        }
      });
    },
    /** 发送短息按钮 */
    handleSendSms(row) {
      this.resetSendSms(row);
      // 设置参数
      this.sendSmsForm.content = row.template;
      this.sendSmsForm.params = row.params;
      this.sendSmsForm.templateCode = row.code;
      this.sendSmsForm.templateParams = row.params.reduce(function (obj, item) {
        obj[item] = undefined;
        return obj;
      }, {});
      // 根据 row 重置 rules
      this.sendSmsRules.templateParams = row.params.reduce(function (obj, item) {
        obj[item] = {required: true, message: '参数 ' + item + " 不能为空", trigger: "change"};
        return obj;
      }, {});
      // 设置打开
      this.sendSmsOpen = true;
    },
    /** 重置发送短信的表单 */
    resetSendSms() {
      // 根据 row 重置表单
      this.sendSmsForm = {
        content: undefined,
        params: undefined,
        mobile: undefined,
        templateCode: undefined,
        templateParams: {}
      };
      //this.resetForm("sendSmsForm");
    },
    /** 取消发送短信 */
    cancelSendSms() {
      this.sendSmsOpen = false;
      this.resetSendSms();
    },
    /** 提交按钮 */
    submitSendSmsForm() {
      // 添加的提交
      sendSms(this.sendSmsForm).then(response => {
        if (response.success) {
          this.$message.success("提交发送成功！发送结果，见发送日志编号：" + response.data);
          this.sendSmsOpen = false;
        } else {
          this.$message.error(response.message);
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.dashboard-editor-container {
  padding: 5px;
  background-color: rgb(240, 242, 245);
  position: relative;

  .content-wrapper {
    background: #fff;
    padding: 16px 16px 0;
    margin-bottom: 32px;
  }
}
.right-toolbar-class {
  position: relative;
  top: 36px;
  right: 10px;
  z-index: 10;
}
.table-content-class{
  margin-top: -10px;
  margin-bottom: 20px;
}
.tips{
  background-color: #fef0f0;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 20px;
  color: #999;
  width: 100%;
  margin-bottom: 5px;
  margin-top: 5px;
}
@media (max-width:1024px) {
  .content-wrapper {
    padding: 8px;
  }
}
</style>
